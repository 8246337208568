import React from "react";
import ReactDOM from "react-dom";

const Redirect = () => {
  window.location.href = "https://nzbasic.com";
};

ReactDOM.render(
  <React.StrictMode>
    <Redirect />
  </React.StrictMode>,
  document.getElementById("root")
);
